import { createApp, h } from 'vue'
import routes from './routes'
require('./assets/css/main.css')

import StockList from './components/stock.vue'
import NavBar from './components/navbar.vue'
import ReturnsFilter from './components/filter-returns.vue'
import SalesFilter from './components/filter-sales.vue'
import Actions from './components/actions.vue'
import Delete from './components/delete.vue'

const SnrManagement = {
  components: {
    StockList,
    NavBar,
    ReturnsFilter,
    Actions,
    Delete, 
    SalesFilter
  },
  data: () => ({
    currentRoute: window.location.pathname
  }),
  computed: {
    ViewComponent () {
      let matchingPage = routes[this.currentRoute] || 'Login';
      
      if(matchingPage != 'Login') {
          if(!sessionStorage.getItem('auth_user')) matchingPage = 'Login';
      }
      else {
        if(sessionStorage.getItem('auth')) matchingPage = 'List';
      }
      return require(`./pages/${matchingPage}.vue`).default
    }
  },

  render () {
    return h(this.ViewComponent)
  },

  created () {
    window.addEventListener('popstate', () => {
      this.currentRoute = window.location.pathname
    })
  }
}

const app = createApp(SnrManagement);
app.mount('#app');