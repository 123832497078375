export function validateDate(d) {
  if(d.length != 10) {        
    return  false;
  }

  if(d.split('.').length != 3) {        
    return false;
  }
  else {
    var arr = d.split('.');
    if(arr[0].length != 2 && arr[1].length != 2 && arr[0].length != 4) {          
      return false;          
    }
  }
  return true;
}

export function formatDate(d) {
  if(!d) return null;
  var arr = d.split('.');
  return arr[2]+'-'+arr[1]+'-'+arr[0];
}
 

export function formatDateReverse(d) {
  if(!d) return null;
  var arr = d.split('-');
  return arr[2]+'.'+arr[1]+'.'+arr[0];
}

export function validateSn(sn) {
  if(!sn) return false;
  return sn.indexOf('.') != -1;
}

export function getQueryString(data = {}) {
  return Object.entries(data)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
}
 
