<template>
    <div class="modal fade" id="delete-modal" tabindex="-1">
      <form class="add-batch" v-on:submit.prevent="delSn">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Seriennummer löschen</h5>            
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row">                  
                <p>Sind Sie sich wirklich sicher, dass Sie die Seriennummer möchten? Dann tippen Sie "{{sn}}" in das Eingabefeld und drücken Sie auf "Löschen"</p>                
                <input 
                  type="text"
                  v-model="snToDelete"
                  id="delete-value" 
                  class="form-control" 
                  :placeholder="sn"
                   aria-label="delete-value" 
                   autocomplete="off">
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div v-show="delSuccess != ''" class="alert alert-success" role="alert">      
                  {{delSuccess}}                    
                </div>
                <div v-show="delErr != ''" class="alert alert-danger" role="alert">      
                  {{delErr}}                    
                </div> 
              </div>
            </div>   
          </div>
          <div class="modal-footer">
            <button :disabled="isDeleting" 
                class="btn btn-danger save-btn" type="submit"><span v-show="!isDeleting">Löschen</span><span v-show="isDeleting" class="spinner-border" role="status"></span></button>          
            <button v-on:click="resetDelete" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
          </div>          
        </div>
      </div>
      </form>
    </div>
</template>
<script>
  import { ref } from 'vue';
  import axios from "axios";
  import {getQueryString} from "../assets/js/helper.js";  
 
  const api = 'https://snr-api.mediola.com/index.php';
  
  export default {
    name: 'Delete',
    setup() {

      let user = sessionStorage.getItem('auth_user');
      let pw = sessionStorage.getItem('auth_pw');

      //add batch
      const isDeleting = ref(false);
      const sn = ref('');
      const snToDelete = ref('');
      const delSuccess = ref('');
      const delErr = ref('');

      function resetDelete() {
        sn.value = '';
        snToDelete.value = '';
        delErr.value = "";
        delSuccess.value = "";
      }

      function delSn() {
        
        var search = document.getElementById('search');
        delErr.value = "";
        delSuccess.value = "";

        if(snToDelete.value == '') {
          delErr.value = 'Geben Sie die Seriennummer ein';
          return;
        }
        else if(sn.value != snToDelete.value) {
          delErr.value = 'Die Seriennummern stimmen nicht überein';
          return;
        }
        
        isDeleting.value = true; 

        axios(
          {
            method: 'post',
            url: api + '/serial/delete',
            data: getQueryString({
              sn: sn.value
            }),
            headers: {
             'Authorization': 'Basic ' + btoa(user+':'+pw),
             'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )        
        .then(response => { 
          snToDelete.value = '';
          isDeleting.value = false;
          if(!response) return;             
          search.dispatchEvent(new Event('keyup'));
          if(response.data && response.data.success) {                            
            delSuccess.value = 'Seriennummern erfolgreich gelöscht';
          }
          else if(response.data && response.data.error) {
            delErr.value = 'Seriennummer nicht gelöscht';
          }                          
        })
        .catch(() => {
          snToDelete.value = '';
          isDeleting.value = false;
          delErr.value = 'Fehler beim Löschen der Seriennummern';
        })

      }

      return {
        sn,
        delSn,
        snToDelete,
        delSuccess,
        delErr,
        isDeleting,
        resetDelete
      }

    },
    mounted() {      
      
      let el = document.getElementById('delete-modal');      
      
      el.addEventListener('shown.bs.modal', (e)=> {
        if(e.relatedTarget && e.relatedTarget.dataset && e.relatedTarget.dataset.sn) {
          this.sn = e.relatedTarget.dataset.sn;
        }
      });      
    }   
  }

</script>
<style scoped>
  .alert {
    margin-top: 20px;  
  }
</style>