<template>
    <div class="modal fade" id="filter-returns" tabindex="-1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Rückläufer</h5>            
          </div>
          <div class="modal-body">
            <form class="returns" v-on:submit.prevent="getReturns">
              <div class="input-group mb-3">
                <span class="input-group-text">Von</span>
                <input type="text" id="from-returns" name="from-returns" class="form-control" placeholder="" aria-label="from" autocomplete="off" required>
                <span class="input-group-text">Bis</span>
                <input type="text" id="till-returns" name="till-returns" class="form-control" placeholder="" aria-label="till" autocomplete="off" required>
                <button :disabled="isBusy ? true : false" 
                        class="btn btn-primary" type="submit"><i class="far fa-arrow-alt-circle-right"></i></button>
              </div>
              <h5>Ergebnis: <span v-show="isBusy" class="spinner-border" role="status"></span></h5>
              <div class="container">
                <div class="row">
                  <table v-show="returns.length > 0" class="table">
                  <thead>
                    <tr>
                      <th scope="col">Typ</th>
                      <th scope="col">Anzahl</th>                      
                    </tr>
                  </thead>    
                  <tbody v-if="returns.length > 0"> 
                    <tr v-for="(item, index) in returns" :key="item.type" 
                      :class="{'even': index % 2 === 0, 'odd': index % 2 !== 0 }">
                      <td>{{item.type}}</td>
                      <td>{{item.amount}}</td>                      
                    </tr>  
                  </tbody>                 
                  </table>
                  <div class="export"><a v-show="returns.length > 0" href="#" v-on:click.prevent="exportReturns">
                    <i class="fas fa-file-csv"></i></a></div>
                <div class="returns center" v-show="returns.length == 0" >Keine Daten</div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button v-on:click="resetReturns" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
          </div>          
        </div>
      </div>
    </div>
</template>
<script>
  import { ref } from 'vue';
  import axios from "axios"; 
  import {formatDate} from "../assets/js/helper.js";  
  
  require('../assets/css/dtsel.css')
  require('../assets/js/dtsel');

  const api = 'https://snr-api.mediola.com/index.php';

  export default {
    name: 'ReturnsFilter',
    setup() {
      const isBusy = ref(false);
      const returns = ref([]);

      async function getReturns() {
        isBusy.value = true; 

        let from = document.getElementById('from-returns').value;
        let till = document.getElementById('till-returns').value;
        
        axios.get(api + '/statistics/returns', {
          params: {
            from: formatDate(from),
            till: formatDate(till)
          },
          auth: {
            username: sessionStorage.getItem('auth_user'),
            password: sessionStorage.getItem('auth_pw'),
        }})
        .then(response => { 
          isBusy.value = false;  
          if(response && response.data) {
            returns.value = response.data;   
          }      
        })
        .catch(() => {
          isBusy.value = false;             
        })
      }

      async function exportReturns() {
        let elements = [
          'manufacturer',
          'sn',
          'type',
          'receipt_date',
          'license',
          'date_sold',
          'customer_no',
          'customer_name',
          'return_date',
          'rented',
          'consignment',
          'comment'
        ];

        isBusy.value = true; 

        let from = document.getElementById('from-returns').value;
        let till = document.getElementById('till-returns').value;
        
        axios.get(api + '/statistics/returns', {
          params: {
            from: formatDate(from),
            till: formatDate(till),
            count: 0
          },
          auth: {
            username: sessionStorage.getItem('auth_user'),
            password: sessionStorage.getItem('auth_pw'),
        }})
        .then(response => { 
          isBusy.value = false;  
          if(response && response.data) {

            var arr = [];
            arr.push(elements);

            for(let i=0; i<response.data.length; i++) {
              let temp = [];
              let el = response.data[i];

              for(let j=0; j<elements.length; j++) {
                temp.push(el[elements[j]]);
              }
              arr.push(temp);
            }
            
            let csvContent = "data:text/csv;charset=utf-8," 
              + arr.map(e => e.join(",")).join("\n");
            var encodedUri = encodeURI(csvContent);
            
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "returns.csv");
            document.body.appendChild(link);

            link.click();  
          }     
        })
        .catch(() => {
          isBusy.value = false;             
        })
      }

      function resetReturns() {
        returns.value = [];
      }

      return {
        isBusy,
        getReturns,
        exportReturns,
        resetReturns,
        returns
      }
    },
    mounted() {
      
      new window.dtsel.DTS('input[name="from-returns"]', {
        dateFormat: "dd.mm.yyyy"
      });

      new window.dtsel.DTS('input[name="till-returns"]', {
        dateFormat: "dd.mm.yyyy"
      });
    }
  }
  </script>
  <style scoped>

  #filter-returns .input-group,
  #filter-returns table,
  #filter-returns .returns h5,
  #filter-returns .export {
    width: 500px;
    margin: 0 auto;
  }

  #filter-returns .spinner-border {
    margin-left: 10px;
    height: 0.8rem;
    width: 0.8rem;
    color: #555;
  }

  #filter-returns .returns h5 {
    text-align: left; 
    margin-bottom: 20px;   
    margin-top: 2px;
  }
  
  #filter-returns table {
    margin-bottom: 20px;
  }

  #filter-returns .export{
    text-align: right;
    font-size:25px;
  } 

</style>