<template>
    <div class="text-center">
      <main class="form-signin">
      <form v-on:submit.prevent="login">
        <img class="logo" src="../assets//mediola.png">        
        <label for="inputEmail" class="visually-hidden">Email address</label>
        <input type="email" id="inputEmail" class="form-control" placeholder="Email address" required autofocus v-model="user">
        <label for="inputPassword" class="visually-hidden">Password</label>
        <input type="password" id="inputPassword" class="form-control" placeholder="Password" required v-model="pw">
        <div class="checkbox mb-3">          
        </div>
        <button :disabled="isLoggingIn ? true : false" class="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>        
      </form>
      <div v-show="isLoggingIn" class="spinner-border" role="status"></div>           
    </main>
    <div v-show="loginError"  class="alert alert-danger" role="alert">
          Sie konnten nicht eingeloggt werden. Bitte überprüfen Sie ihre Logindaten
    </div> 
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from "axios";

const api = 'https://snr-api.mediola.com/index.php';

export default {  
  setup() {    
    

    const isLoggingIn = ref(false);
    const loginError = ref(false);
    const user = ref('');
    const pw = ref('');
    
    async function login() {
      
      isLoggingIn.value = true; 
      loginError.value = false;

      axios.get(api + '/login', {
        auth: {
          username: user.value,
          password: pw.value
      }})
      .then(response => { 
        isLoggingIn.value = false;        
        if(response.data && response.data.email) {
          sessionStorage.setItem('auth_user', user.value);
          sessionStorage.setItem('auth_pw', pw.value);
          sessionStorage.setItem('user', JSON.stringify(response.data));
          window.location.pathname = '/list';
        }
      })
      .catch(err => {
        isLoggingIn.value = false;
        
        if(err.response && err.response.status && err.response.status == 401) {
          loginError.value = true;
        }        
      })
    }
    return {
      isLoggingIn,
      loginError,
      user,
      pw,
      login,
    }
  },
}
</script>
<style scoped>
  html,
  body {
    height: 100%;
  }

  body {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
  }

  .logo {
    width: 200px;
    margin-bottom: 40px;
    margin-top: 100px;
  }

  .alert-danger {
    width: 50%;
    margin: 0 auto;
  }
  
  .spinner-border {
    margin-top: 20px;
    color: #555;
  }

  .form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
  }
  .form-signin .checkbox {
    font-weight: 400;
  }
  .form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }
  .form-signin .form-control:focus {
    z-index: 2;
  }
  .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
</style>