<template>
    <div class="container">
      <div class="row">
        <div class="col-sm stock">
          <h4><span>Aktuelle Lagerbestände</span> 
            <div v-show="isUpdatingStock" class="spinner-border" role="status"></div>
            <a href="#" v-on:click.prevent="getStock">
              <i v-show="!isUpdatingStock" class="fas fa-sync"></i>
            </a>
            <span class="stock-updated">Stand: {{stockUpdated}}</span>
          </h4>
          <span v-for="(item, index) in stock" :key="item.id" 
            class="stock-item" :class="{'even': index % 2 === 0, 'odd': index % 2 !== 0 }">
            {{item.type.toUpperCase()}} <span class="badge bg-secondary">{{item.stock}}</span>            
          </span>          
        </div>
      </div> 
    </div>  
</template>

<script>
  import { ref } from 'vue';
  import axios from "axios";
  import {formatDateReverse as formatDate} from "../assets/js/helper.js";  
  
  //put in global variable
  const api = 'https://snr-api.mediola.com/index.php';

  export default {
    name: 'StockList',  
    setup() {
      
      const isUpdatingStock = ref(false);
      const stockUpdated = ref('');
      const stock = ref([]);

      async function getStock(event, url) {
        
        if(!url) url = 'stock_update';
        isUpdatingStock.value = true; 

        axios.get(api + '/statistics/' + url, {
          auth: {
            username: sessionStorage.getItem('auth_user'),
            password: sessionStorage.getItem('auth_pw'),
        }})
        .then(response => { 
          if(url == 'stock_update') {
            this.getStock(null, 'stock');
            return;
          }
          isUpdatingStock.value = false;        
          stock.value = response.data;
          if(response.data[0] && response.data[0].updated) {
            var arr = response.data[0].updated.split(' ');
            stockUpdated.value = formatDate(arr[0])+' '+arr[1];
          }
        })
        .catch(() => {
          isUpdatingStock.value = false;          
        })
      }      

      return {
        isUpdatingStock,
        stockUpdated,
        stock,
        getStock,
        formatDate
      }
    },
    mounted: function (){
      this.getStock(null, 'stock');
    }    
  }
</script>
<style scoped>

  .stock {
    margin: 20px 0 30px 0;
  }

  .stock h4 span {
    margin-right: 20px;
  }

  .stock h4 i {
    font-size: 16px;
  }

  .stock-item {
    margin: 15px 20px 20px 0;
    display: inline-block;
  }

  .stock-item .badge {
    margin-left: 7px;
  }

  .stock-updated {
    margin-left: 25px;
    color: #bbb;
    font-size: 12px;
  }

</style>
