<template>
    <div class="col-sm actions"> 
      <ul>
        <li><a data-bs-toggle="modal" data-bs-target="#add-batch" href="#"><i class="far fa-plus-square"></i> Neu Serie</a></li>
        <li><a data-bs-toggle="modal" data-bs-target="#add-new" href=""><i class="far fa-plus-square"></i> Neu</a></li>
      </ul>         
    </div>
    <div class="modal fade" id="add-batch" tabindex="-1">
      <form class="add-batch" v-on:submit.prevent="addBatch">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Anlegen Serie</h5>            
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row">                                  
                <div class="container">
                  <div class="row">
                    <div class="col-sm">
                      <h5>Produzent</h5>
                      <select v-model="batchVendor" class="form-select" aria-label=".form-select-sm manufacturer" required>
                        <option value="1">WIR Elektronik</option>
                        <option value="2">Eltako</option>
                        <option value="3">Refurbished</option>
                      </select>
                    </div>
                    <div class="col-sm"> 
                      <h5>Typ</h5> 
                      <select v-model="batchType" class="form-select" aria-label=".form-select-sm type" required>
                        <option value="GAT-6010">GAT-6010</option>
                        <option value="GAT-6010-E">GAT-6010-E</option>
                        <option value="GAT-6020">GAT-6020</option>
                        <option value="GAT-6020-E">GAT-6020-E</option>
                        <option value="GAT-6040">GAT-6040</option>
                        <option value="GAT-5040">GAT-5040</option>
                        <option value="GAT-5020">GAT-5020</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <h5>Seriennummern</h5>
                    <div class="input-group mb-3">
                      <span class="input-group-text">Von</span>
                      <input type="text" id="from-sn-batch" v-model="batchFrom" name="from-sn-batch" class="form-control" placeholder="" aria-label="from" autocomplete="off" required>
                      <span class="input-group-text">Bis</span>
                      <input type="text" id="to-sn-batch" v-model="batchTo" name="to-sn-batch" class="form-control" placeholder="" aria-label="to" autocomplete="off" required>
                    </div> 
                  </div>
                  <div class="row">
                    <div class="col-sm">
                      <h5>Eingangsdatum</h5>
                      <input type="text" id="receipt_date-sn-batch" name="receipt_date-sn-batch" class="form-control" placeholder="" aria-label="receipt_date" autocomplete="off" required>
                    </div>
                    <div class="col-sm"></div>
                  </div>                  
                </div>   
              </div>
            </div>  
            <div class="container">
              <div class="row">
                <div v-show="batchSuccess != ''" class="alert alert-success" role="alert">      
                  {{batchSuccess}}                    
                </div>
                <div v-show="batchErr != ''" class="alert alert-danger" role="alert">      
                  {{batchErr}}                    
                </div> 
              </div>
            </div> 
          </div>
          <div class="modal-footer">
            <button :disabled="batchIsBusy" 
              class="btn btn-primary save-btn" type="submit"><span v-show="!batchIsBusy">Speichern</span><span v-show="batchIsBusy" class="spinner-border" role="status"></span></button>                     
            <button v-on:click="resetBatch" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
          </div>          
        </div>
      </div>
      </form>
    </div>
    <div class="modal fade" id="add-new" tabindex="-1">
      <form class="add-new" v-on:submit.prevent="saveSn">     
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-show="!isEditing" class="modal-title">Neue Seriennummer anlegen</h5>            
            <h5 v-show="isEditing" class="modal-title">Seriennummer bearbeiten<span v-show="isGettingSerial" class="spinner-border" role="status"></span></h5>            
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row">                             
                  <div class="container">
                    <div class="row">
                       <div class="col-sm">
                      <h5>Seriennummer</h5>
                      <div class="input-group mb-3">
                        <input type="text" v-model="newSn" id="new-sn" name="new-sn" class="form-control" placeholder="" aria-label="new-sn" autocomplete="off" required :disabled="isEditing">                        
                      </div> 
                       </div>
                        <div class="col-sm">
                        </div>
                    </div>
                    <div class="row">
                      <div class="col-sm">
                        <h5>Produzent</h5>
                        <select v-model="newVendor" class="form-select" aria-label=".form-select-sm newManufacturer" required>
                          <option value="1">WIR Elektronik</option>
                          <option value="2">Eltako</option>
                          <option value="3">Refurbished</option>
                        </select>
                      </div>
                      <div class="col-sm"> 
                        <h5>Typ</h5> 
                        <select v-model="newType" class="form-select" aria-label=".form-select-sm newType" required>
                          <option value="GAT-6010">GAT-6010</option>
                          <option value="GAT-6010-E">GAT-6010-E</option>
                          <option value="GAT-6020">GAT-6020</option>
                          <option value="GAT-6020-E">GAT-6020-E</option>
                          <option value="GAT-6040">GAT-6040</option>
                          <option value="GAT-5040">GAT-5040</option>
                          <option value="GAT-5020">GAT-5020</option>
                        </select>
                      </div>
                    </div>                    
                    <div class="row">
                      <div class="col-sm">
                        <h5>Eingangsdatum</h5>
                        <input type="text" id="new_receipt_date" name="new_receipt_date" class="form-control" placeholder="" aria-label="new_receipt_date" autocomplete="off" required>
                      </div>
                      <div class="col-sm">
                        <div class="form-check new-license">
                          <input v-model="newLicense" type="checkbox" class="form-check-input" id="new_license">
                          <label class="form-check-label" for="license">Lizenz</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm">
                        <h5>Verkaufsdatum</h5>
                        <input type="text" id="new_date_sold" name="new_date_sold" class="form-control" placeholder="" aria-label="new_date_sold" autocomplete="off">
                      </div>
                      <div class="col-sm">
                        <div class="form-check kommi">
                          <input v-model="newKommi" type="checkbox" class="form-check-input" id="new_kommi">
                          <label class="form-check-label" for="new_kommi">Kommi</label>
                        </div>
                      </div>                                           
                    </div>
                    <div class="row">
                      <div class="col-sm">
                        <h5>Kundennummer</h5>
                        <input type="text" v-model="newCustomerNo" class="form-control" placeholder="" aria-label="" autocomplete="off">
                      </div> 
                      <div class="col-sm new-customer-name">
                        <h5>Kundenname</h5>
                        <input type="text" v-model="newCustomerName" class="form-control" placeholder="" aria-label="" autocomplete="off">
                        <a v-on:click.prevent="getCustomerName" href="#">
                          <i v-show="!isGettingCustomer" class="far fa-address-book"></i>
                          <span v-show="isGettingCustomer" class="spinner-border" role="status"></span>
                        </a>
                      </div>                                            
                    </div> 
                    <div class="row">
                      <div class="col-sm">
                        <h5>Rückläufer</h5>
                        <input type="text" id="new_return_date" name="new_return_date" class="form-control" placeholder="" aria-label="" autocomplete="off">
                      </div>
                      <div class="col-sm"> 
                        <h5>Test</h5> 
                        <select v-model="newTest" class="form-select" aria-label=".form-select-sm type">
                          <option value="0"></option>
                          <option value="30">30 Tage</option>
                          <option value="60">60 Tage</option>
                          <option value="90">90 Tage</option>
                          <option value="8888">Dauerhaft</option>
                        </select>
                      </div>
                    </div>    
                    <div class="row">
                      <div class="col-sm">
                        <div class="form-group">
                          <h5>Kommentar</h5>
                          <textarea v-model="newComment" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                      </div>                      
                    </div>                                 
                  </div>                    

              </div>
            </div>  
            <div class="container">
              <div class="row">
                <div v-show="newSuccess != ''" class="alert alert-success" role="alert">      
                  {{newSuccess}}                    
                </div>
                <div v-show="newErr != ''" class="alert alert-danger" role="alert">      
                  {{newErr}}                    
                </div> 
              </div>
            </div> 
          </div>
          <div class="modal-footer">
             <button :disabled="newIsBusy" 
                class="btn btn-primary save-btn" type="submit"><span v-show="!newIsBusy">Speichern</span><span v-show="newIsBusy" class="spinner-border" role="status"></span></button>
            <button v-on:click="resetNew" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
          </div>          
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  import { ref } from 'vue';
  import axios from "axios";
  import {validateDate, formatDate, validateSn, getQueryString, formatDateReverse} from "../assets/js/helper.js";  

  require('../assets/css/dtsel.css')
  require('../assets/js/dtsel.js');
  
  const api = 'https://snr-api.mediola.com/index.php';
  
  
  export default {
    name: 'Actions',
    setup() {

      let user = sessionStorage.getItem('auth_user');
      let pw = sessionStorage.getItem('auth_pw');

      //add batch
      const batchIsBusy = ref(false);
      const batchFrom = ref('');
      const batchTo = ref('');
      const batchVendor = ref('');
      const batchType = ref('');   
      const batchSuccess = ref('');   
      const batchErr = ref('');         

      //add serial
      const newSn = ref('');
      const newVendor = ref('');
      const newType = ref('');
      const newLicense = ref(false);
      const newDateSold = ref('');
      const newKommi = ref(false);
      const newCustomerNo = ref('');
      const newCustomerName = ref('');
      const newTest = ref('');
      const newComment = ref('');
      const isGettingCustomer = ref(false);
      const newIsBusy = ref(false);
      const newSuccess = ref('');   
      const newErr = ref('');
      const isEditing = ref(false)
      const isGettingSerial = ref(false);

      function addBatch() {
 
        let d = document.getElementById('receipt_date-sn-batch').value;
        
        batchSuccess.value = '';
        batchErr.value = '';

        if(!validateDate(d)) { 
          batchErr.value = "Ungültiges Datumformat";
          return;        
        }

        batchIsBusy.value = true;

        d = formatDate(d);
        axios(
          {
            method: 'post',
            url: api + '/serials/add_batch',
            data: getQueryString({
              manufacturer: batchVendor.value,
              type: batchType.value,
              from: batchFrom.value,
              to: batchTo.value,
              receipt_date: d
            }),
            headers: {
             'Authorization': 'Basic ' + btoa(user+':'+pw),
             'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )        
        .then(response => { 
            batchIsBusy.value = false;
            if(!response) return;             

            if(response.data && response.data.success) {              
              this.resetBatch();
              batchSuccess.value = 'Seriennummern erfolgreich gespeichert';
            }
            else if(response.data && response.data.error) {
              var er = response.data;

              if(er.code && er.code == '004') {
                batchErr.value = 'Ungültiges Seriennummernformat';
                return;
              }
              else if(er.code && er.code == '003') {
                batchErr.value = 'Seriennummern Von ist größer als Bis';
                return;
              }
              else if(er.code && er.code == '002') {
                batchErr.value = 'Es existieren bereits Seriennummern aus dieser Serie.';
                
                if(er.serials && er.serials.length) {                    
                    let csvContent = "data:text/csv;charset=utf-8,";
                    er.serials.map(e => csvContent += e+',\n');

                    var encodedUri = encodeURI(csvContent);
                    var link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", "duplicate_serials.csv");
                    document.body.appendChild(link);

                    link.click();
                }
                return;
              }
            }
        })
        .catch(() => {
          batchIsBusy.value = false;
          batchErr.value = 'Fehler beim Speichern der Seriennummern';
        })
      }

      function resetBatch() {
        document.getElementById('receipt_date-sn-batch').value = '';
        batchErr.value = '';
        batchSuccess.value = '';
        batchFrom.value = '';
        batchTo.value = '';
        batchType.value = '';
        batchVendor.value = '';
        batchIsBusy.value = false;
      }

      function getCustomerName() {
        var customer = newCustomerNo.value;
        if(!customer) return;        

        isGettingCustomer.value = true; 

        axios.get(api + '/serials/customer', {
          params: {
            customer: customer,            
          },
          auth: {
            username: user,
            password: pw,
        }})
        .then(response => { 
          isGettingCustomer.value = false;  

          if(response.data && response.data.success && response.data.customer) {
            newCustomerName.value = response.data.customer;
          }
          else if(response.data && response.data.error) {
            newCustomerName.value = "";
          }
        })
        .catch(() => {
          isGettingCustomer.value = false;
        })
      }

      function resetNew() {

        document.getElementById('new_receipt_date').value = '';
        document.getElementById('new_date_sold').value = '';
        document.getElementById('new_return_date').value = '';
        newSn.value = '';
        newType.value = '';
        newVendor.value = '';
        newLicense.value = false;
        newKommi.value = false;        
        newCustomerNo.value = '';
        newCustomerName.value = '';
        newTest.value = '';
        newComment.value = '';
        newIsBusy.value = false;
        
        newErr.value = '';
        newSuccess.value = '';
        isEditing.value = false;
        isGettingSerial.value = false; 
      }

      function saveSn() {        
        
        newIsBusy.value = false;
        newErr.value = '';
        newSuccess.value = '';

        var search = document.getElementById('search');
  
        if(!validateSn(newSn.value)) {
          newErr.value = 'Ungültiges Seriennummernformat';
          return;
        }

        var receipt_date = document.getElementById('new_receipt_date').value;
        if(!receipt_date ||!validateDate(receipt_date)) {
          newErr.value = 'Ungültiges Wareneingangsdatum';
          return;
        }
        
        var date_sold = document.getElementById('new_date_sold').value;
        if(date_sold && !validateDate(date_sold)) {
          newErr.value = 'Ungültiges Verkaufsdatum';
          return;
        }

        var return_date = document.getElementById('new_return_date').value;
        if(return_date && !validateDate(return_date)) {
          newErr.value = 'Ungültiges Rückläuferdatum';
          return;
        }

        var data = {
          sn: newSn.value,
          receipt_date: formatDate(receipt_date),
          manufacturer: newVendor.value,
          type: newType.value
        }

        data.license = newLicense.value ? 1 : 0;
        data.consignment = newKommi.value ? 1 : 0;
        
        if(date_sold) {
          data.date_sold = formatDate(date_sold);
        }

        if(return_date) {
          data.return_date = formatDate(return_date);
        }

        if(newCustomerNo.value || newCustomerNo.value == '') {
          data.customer_no = newCustomerNo.value;
        }

        if(newCustomerName.value || newCustomerName.value == '') {
          data.customer_name = newCustomerName.value;
        }

        if(newTest.value) {
          data.rented = newTest.value;
        }

        if(newComment.value || newComment.value == '') {
          data.comment = newComment.value;
        }

        newIsBusy.value = true;

        if(!isEditing.value) {

          axios(
            {
              method: 'post',
              url: api + '/serials/add',
              data: getQueryString(data),
              headers: {
              'Authorization': 'Basic ' + btoa(user+':'+pw),
              'Content-Type': 'application/x-www-form-urlencoded'
              }
            }
          )        
          .then(response => { 
              newIsBusy.value = false;
              if(!response) return;             

              if(response.data && response.data.success) {              
                //probably would be better to populate changes to parent component
                search.dispatchEvent(new Event('keyup'));
                this.resetNew();
                newSuccess.value = 'Seriennummern erfolgreich gespeichert';   
                return;             
              }
              else if(response.data && response.data.error) {
                var er = response.data;

                if(er.code && er.code == '004') {
                  newErr.value = 'Ungültiges Seriennummernformat';
                  return;
                }              
                else if(er.code && er.code == '001') {
                  newErr.value = 'Seriennummer existiert bereits';                                
                  return;
                }
              }
          })
          .catch(() => {
            newIsBusy.value = false;
            newErr.value = 'Fehler beim Speichern der Seriennummer';
          })

        }
        else {
          
          data.sn = newSn.value; 
          
          axios(
            {
              method: 'post',
              url: api + '/serials/update',
              data: getQueryString(data),
              headers: {
              'Authorization': 'Basic ' + btoa(user+':'+pw),
              'Content-Type': 'application/x-www-form-urlencoded'
              }
            }
          )        
          .then(response => { 
              newIsBusy.value = false;
              if(!response) return;   
                    
              if(response.data && response.data.success) {                              
                //probably would be better to populate changes to parent component
                search.dispatchEvent(new Event('keyup'));
                this.resetNew();
                newSuccess.value = 'Seriennummern erfolgreich gespeichert';      
                return;          
              }
              else if(response.data && response.data.error) {
                newErr.value = 'Fehler beim Speichern der Seriennummer';
                return;                
              }
          })
          .catch(() => {
            newIsBusy.value = false;
            newErr.value = 'Fehler beim Speichern der Seriennummer';
          })
        }
      }

      function setNewSerial(data) {
        
        newSn.value = data.sn;        
        newVendor.value = data.manufacturer;
        newType.value = data.type.toUpperCase();
        
        document.getElementById('new_receipt_date').value = formatDateReverse(data.receipt_date);
        
        newTest.value = data.rented;

        if(data.license == 0) newLicense.value = false;
        else if(data.license == 1) newLicense.value = true;
        
        if(data.consignment == 0) newKommi.value = false;
        else if(data.consignment == 1) newKommi.value = true;

        if(data.customer_no) {
          newCustomerNo.value = data.customer_no;
        }

        if(data.customer_name) {
          newCustomerName.value = data.customer_name;
        }

        if(data.date_sold) {
          document.getElementById('new_date_sold').value = formatDateReverse(data.date_sold);
        }

        if(data.return_date) {
          document.getElementById('new_return_date').value = formatDateReverse(data.return_date);
        }

        if(data.comment) {
          newComment.value = data.comment;
        }        
      } 

      function getSn(sn) {

        isEditing.value = true;
        isGettingSerial.value = true; 

        newErr.value = '';
        newSuccess.value = '';
        
        axios.get(api + '/serial', {
          params: {
            sn: sn
          },
          auth: {
            username: user,
            password: pw,
        }})
        .then(response => { 
          isGettingSerial.value = false; 
          console.log(response.data)
          if(response && response.data) {
            
            if(response.data.serial) {
              console.log(response.data)
              var serial = response.data.serial;
              console.log(serial)
              setNewSerial(serial);
            }
            else if(response.data.code && response.data.code) { 
              newErr.value = 'Seriennummer nicht gefunden!';
            }
          }
        })
        .catch(() => {
          isGettingSerial.value = false;
          //newErr.value = 'Seriennummer nicht gefunden! 2';
        })
      }

      return {
        //add batch
        batchIsBusy,
        batchFrom,
        batchTo,
        batchVendor,
        batchType,     
        batchErr,
        batchSuccess,
        addBatch,
        resetBatch,
        
        //add serial
        newSn,
        newCustomerNo,
        newCustomerName,
        getCustomerName,
        isGettingCustomer,
        newVendor,
        newType,
        newLicense,
        newDateSold,
        newKommi,
        newTest,
        newComment,
        saveSn,
        newIsBusy,
        newSuccess,
        newErr,
        resetNew,
        getSn,
        isEditing,
        isGettingSerial
      }
    },
    mounted() {      
      new window.dtsel.DTS('input[name="receipt_date-sn-batch"]', {
        dateFormat: "dd.mm.yyyy"
      });

      new window.dtsel.DTS('input[name="new_receipt_date"]', {
        dateFormat: "dd.mm.yyyy"
      });

      new window.dtsel.DTS('input[name="new_date_sold"]', {
        dateFormat: "dd.mm.yyyy"
      });

      new window.dtsel.DTS('input[name="new_return_date"]', {
        dateFormat: "dd.mm.yyyy"
      });      

      let el = document.getElementById('add-new');      
      
      el.addEventListener('shown.bs.modal', (e)=> {
 
        if(e.relatedTarget && e.relatedTarget.dataset && e.relatedTarget.dataset.sn) {
          this.getSn(e.relatedTarget.dataset.sn);
        }
      });      
    }   
  }

</script>
<style scoped>

  h5 {
    margin-top: 10px;
  }

  h5 span.spinner-border {
    margin-left: 20px;
    height: 1.5rem;
    width: 1.5rem;
  }


  .actions {
    text-align: right;
    margin-right: 0;
    margin-left: 20px;
  }

  .actions li {
    display:inline;
    margin-right: 0;
    margin-left: 25px;
  }

  .actions ul {
    margin-left: 0px;
    padding-left: 0px;
  }

  .add-batch h5 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .save-btn {
    margin-right: 30px;
  }

  .alert.alert-danger,
  .alert.alert-success {
    margin-top: 40px;
  }

  .new-license,
  .kommi {
    margin-top: 50px;
  }

  .new-customer-name input {
    width: 88%;
    display: inline-block;
  }

  .new-customer-name a {
    font-size: 24px;
    margin-left: 7px;
  }
</style>