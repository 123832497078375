<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light rounded">
      <div class="container-fluid">        
        <div class="collapse navbar-collapse justify-content-md-end user-menu">
          <ul class="navbar-nav">           
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="dropdown10" data-bs-toggle="dropdown" aria-expanded="false">{{first}} {{last}}</a>
              <ul class="dropdown-menu" aria-labelledby="dropdown10">
                <li><a class="dropdown-item" href="#" v-on:click.prevent="logout">Ausloggen</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav> 
</template>
<script>
  import { ref } from 'vue';
   
 function getUserData() {
    var user = {};
    try {
        user = JSON.parse(sessionStorage.getItem('user'));
    } catch(e) {
        user = {};
    }
    return user; 
  }

  export default {
    name: 'NavBar',    
    setup() {
      
      const first = ref(getUserData().firstname);
      const last = ref(getUserData().lastname);  

      function logout() {
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('auth_user');
        sessionStorage.removeItem('auth_pw');
        window.location.pathname = '/login';
      }

      return {
        first,
        last,        
        logout}
    },
  }
</script>
<style scoped>
  .user-menu {
    margin-right: 30px;
  }

</style>