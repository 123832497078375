<template>   
    <nav-bar />
    <stock-list />
    <div class="container">
      <div class="row">
        <div class="col-sm filter">
          <h6>Filter</h6>
          <ul>
            <li><a data-bs-toggle="modal" data-bs-target="#filter-sales" href="#"><i class="fas fa-filter"></i> Verkaufte Gateways</a></li>
            <li><a data-bs-toggle="modal" data-bs-target="#filter-returns" href="#"><i class="fas fa-filter"></i> Rückläufer</a></li>
          </ul>                
        </div>
      </div> 
    </div>
    <returns-filter />
    <sales-filter />
    <delete />
    <div class="container">
      <div class="row">
        <div class="col-sm search">
          <div class="input-group mb-3 search-input">
            <input 
              id="search"
              v-on:keyup="search"
              type="search" 
              class="form-control" 
              placeholder="Suche" 
              aria-label="Suche"
              autocomplete="off">              
          </div>
          <span v-show="searching" class="spinner-border" role="status"></span>
        </div>        
        <div class="col-sm">                  
        </div>
        <actions />        
      </div>
    </div>   
    <div class="container">
      <div class="row"> 
        <table class="table">
        <thead>
          <tr>
            <th scope="col">Lieferant</th>
            <th scope="col">S/N</th>
            <th scope="col"></th>
            <th scope="col">Eingang</th>
            <th scope="col">Lizenz</th>
            <th scope="col">Verkauf</th>
            <th scope="col">Kunde</th>
            <th scope="col"></th>
            <th scope="col">Rückläufer</th>
            <th scope="col">Test</th>
            <th scope="col">Kommi</th>
            <th scope="col">Kommentar</th>
            <th scope="col"></th>
          </tr>
        </thead>    
        <tbody v-if="items.length > 0"> 
          <tr v-for="(item, index) in items" :key="item.sn" 
            :class="{'even': index % 2 === 0, 'odd': index % 2 !== 0 }">
            <td>{{item.manufacturer}}</td>
            <td>{{item.sn}}</td>
            <td>{{item.type}}</td>
            <td>{{formatDate(item.receipt_date)}}</td>        
            <td><span v-if="item.license == 1"><i class="far fa-check-circle"></i></span></td>
            <td>{{formatDate(item.date_sold)}}</td>
            <td>{{item.customer_no}}</td>
            <td>{{item.customer_name}}</td>
            <td>{{formatDate(item.return_date)}}</td>
            <td>
                <span v-if="item.rented != 8888 && item.rented != 0">{{item.rented}} Tage</span>
                <span v-else-if="item.rented == 8888">Dauerhaft</span>
            </td>
            <td><span v-if="item.consignment != 0"><i class="far fa-check-circle"></i></span></td>
            <td>{{item.comment}}</td>
            <td>
              <a href="#" v-bind:data-sn="item.sn" v-bind:data-index="index" data-bs-toggle="modal" data-bs-target="#add-new"><i class="far fa-edit"></i></a>
              <a href="#" v-bind:data-sn="item.sn" v-bind:data-index="index" data-bs-toggle="modal" data-bs-target="#delete-modal"><i class="far fa-trash-alt"></i></a>
            </td>
          </tr>  
        </tbody>
        <tbody v-else>
            <tr>
              <td class="center" colspan="13">Keine Seriennummern gefunden</td>
            </tr> 
        </tbody>
        </table>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <button v-on:click.prevent="getItems" id="load-more" type="button" class="btn btn-light btn-sm">
          <div v-show="isGettingItems" class="spinner-border" role="status"></div>
          Mehr Laden</button>
      </div>
    </div>
</template>

<script>
  import { ref } from 'vue';
  import axios from "axios";
  import {formatDateReverse as formatDate} from "../assets/js/helper.js";  

//put in global variable
  const api = 'https://snr-api.mediola.com/index.php';

  export default {
    name: 'List',    
    setup() {

      const searching = ref(false);      
      const isGettingItems = ref(false);
      const currentPage = ref(1);
      const items = ref([]);

      async function getItems(amt) {
        
        if(amt) amt = 25; 

        isGettingItems.value = true; 

        axios.get(api + '/serials', {
          params: {
            items: amt,
            page: currentPage.value
          },
          auth: {
            username: sessionStorage.getItem('auth_user'),
            password: sessionStorage.getItem('auth_pw'),
        }})
        .then(response => { 
          isGettingItems.value = false;  
        
          if(currentPage.value == 1) {
            items.value = response.data;
          }
          else {
            response.data.forEach((el)=> {
              items.value.push(el);
            });
          }

          currentPage.value++;
        })
        .catch(() => {
          isGettingItems.value = false;              
        })
      }      

      async function search(event) {
        
        currentPage.value = 1;
        var st = event.target.value;
        
        if(st === "") {
          this.getItems(50);
          return;
        }

        if(st.length < 3) return;

        searching.value = true; 

        axios.get(api + '/search', {
          params: {
            searchterm: st
          },
          auth: {
            username: sessionStorage.getItem('auth_user'),
            password: sessionStorage.getItem('auth_pw'),
        }})
        .then(response => { 
          searching.value = false;
          if(response.data) {
            items.value = response.data;
          }          
        })
        .catch(() => {
          searching.value = false;         
        })
      }

      return {
        searching,
        search,        
        isGettingItems,
        items,
        getItems,
        formatDate
      }
    },
    mounted: function (){
      this.getItems(25);      
    }
    
  }
</script>
<style scoped>
  
  .search {
    position: relative;
  }

  .search-input {
    width: 90%;
  }

  .search .spinner-border {
    position: absolute;
    left: 92%;
    top: 8px;
    width: 1rem;
    height: 1rem;
    margin-top:3px;
  }

  #load-more .spinner-border { 
     width: .8rem;
    height: .8rem;
    margin-right: 10px;
    color: #555;
  }

  .table {
    width: 98%;
    margin: 0 auto;
  }

  .table tr:hover {
    background: rgb(219, 219, 219) !important;
  }

  .table tr.odd {
    background: #f8f9fa;
  }
  
  .table th {
    color: #005281;
  }

  .table td {
    font-size: 13px;
  }

  .table tr th:nth-child(5) { text-align: center}
  .table tr th:nth-child(11) { text-align: center}

  .table tr td:nth-child(1) { width: 110px}
  .table tr td:nth-child(2) { width: 80px}
  .table tr td:nth-child(3) { width: 95px}
  .table tr td:nth-child(4) { width: 100px}
  .table tr td:nth-child(5) { width: 80px; text-align: center}
  .table tr td:nth-child(6) { width: 100px;}
  .table tr td:nth-child(7) { width: 80px;}
  .table tr td:nth-child(8) { max-width: 150px;}
  .table tr td:nth-child(9) { width: 125px;}
  .table tr td:nth-child(10) { width: 75px;}
  .table tr td:nth-child(11) { width: 80px; text-align: center}

  #load-more {
    width: 250px;
    margin: 20px auto;
  }

  .fa-trash-alt {
    color: #A91E00;
    margin-left: 10px;
  }

  .filter {
    margin: 0px 0 30px 0;
  }

  .filter ul {
    margin-left: 0px;
    padding-left: 0px;
  }

  .filter li {
    display:inline;
    margin-right: 20px;
  }

</style>