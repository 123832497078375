<template>
    <div class="modal fade" id="filter-sales" tabindex="-1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Verkaufte Gateways</h5>            
          </div>
          <div class="modal-body">
            <form class="returns" v-on:submit.prevent="getSales">
              <div class="container">
                <div class="row">
                  <h5>Zeitraum</h5>
                  <div class="input-group mb-3">                    
                    <span class="input-group-text">Von</span>
                    <input type="text" id="from-sales" name="from-sales" class="form-control" placeholder="" aria-label="from" autocomplete="off" required>
                    <span class="input-group-text">Bis</span>
                    <input type="text" id="till-sales" name="till-sales" class="form-control" placeholder="" aria-label="till" autocomplete="off" required>                
                  </div>
                </div>
                <div class="row mb-3">                  
                  <div class="col-sm"> 
                    <h5>Typ</h5>
                    <select v-model="type" class="form-select" aria-label=".form-select-sm type" required>
                        <option value="GAT-6010">GAT-6010</option>
                        <option value="GAT-6010-E">GAT-6010-E</option>
                        <option value="GAT-6020">GAT-6020</option>
                        <option value="GAT-6020-E">GAT-6020-E</option>
                        <option value="GAT-6040">GAT-6040</option>
                        <option value="GAT-5040">GAT-5040</option>
                        <option value="GAT-5020">GAT-5020</option>
                      </select>
                  </div>
                  <div class="col-sm"> 
                    <h5>Kunden Nr.</h5>
                    <input v-model="customerNo" type="text" id="customer-no-sales" name="customer-no-sales" class="form-control" placeholder="" aria-label="till" autocomplete="off">                
                  </div>
                </div>
                <div class="row mb-3">                      
                  <div class="col-sm">
                    <h5>Lizenz</h5>
                    <div class="form-check new-license">
                      <input v-model="license" type="checkbox" class="form-check-input" id="license">
                      <label class="form-check-label" for="license">Lizenz</label>
                    </div>
                  </div>
                  <div class="col-sm">
                    <button :disabled="isGettingData ? true : false" 
                        class="btn btn-primary" type="submit"><i class="far fa-arrow-alt-circle-right"></i></button>
                  </div>
                </div>
              </div>              
              <div class="container">
                <div class="row">
                  <h5>Ergebnis: <span v-show="isGettingData" class="spinner-border" role="status"></span></h5>
                  <table v-show="items.length > 0" class="table">
                  <thead>
                    <tr>
                      <th scope="col">Typ</th>
                      <th scope="col">Anzahl</th>                      
                    </tr>
                  </thead>    
                  <tbody v-if="items.length > 0"> 
                    <tr v-for="(item, index) in items" :key="item.type" 
                      :class="{'even': index % 2 === 0, 'odd': index % 2 !== 0 }">
                      <td>{{item.type}}</td>
                      <td>{{item.amount}}</td>                      
                    </tr>  
                  </tbody>                 
                  </table>  
                  <div class="export"><a v-show="items.length > 0" href="#" v-on:click.prevent="exportSales">
                    <i class="fas fa-file-csv"></i></a></div>
                  <div class="returns center" v-show="items.length == 0" >Keine Daten</div>                
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button v-on:click="resetSales" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
          </div>          
        </div>
      </div>
    </div>
</template>
<script>
  import { ref } from 'vue';
  import axios from "axios"; 
  import {formatDate} from "../assets/js/helper.js";  
  
  require('../assets/css/dtsel.css')
  require('../assets/js/dtsel');

  const api = 'https://snr-api.mediola.com/index.php';

  export default {
    name: 'SalesFilter',
    setup() {
      
      const isGettingData = ref(false);      
      const type = ref('');      
      const license = ref(false);      
      const customerNo = ref('');
      const items = ref([]);

      async function getSales() {        

        let from = document.getElementById('from-sales').value;
        let till = document.getElementById('till-sales').value;

        isGettingData.value = true; 

        let params = {
          from: formatDate(from),
          till: formatDate(till),
          type: type.value
        };

        if(customerNo.value != '') {
          params['customer_no'] = customerNo.value;
        }

        if(license.value == true) {
          params['license'] = '1';
        }
        
        axios.get(api + '/statistics/sales', {
          params: params,
          auth: {
            username: sessionStorage.getItem('auth_user'),
            password: sessionStorage.getItem('auth_pw'),
        }})
        .then(response => { 
          isGettingData.value = false;  
          console.log(response)
          if(response && response.data) {
            items.value = response.data;   
          }      
        })
        .catch(() => {
          isGettingData.value = false;             
        })
      }

      async function exportSales() {
        let elements = [
          'manufacturer',
          'sn',
          'type',
          'receipt_date',
          'license',
          'date_sold',
          'customer_no',
          'customer_name',
          'return_date',
          'rented',
          'consignment',
          'comment'
        ];

        let from = document.getElementById('from-sales').value;
        let till = document.getElementById('till-sales').value;

        isGettingData.value = true; 

        let params = {
          from: formatDate(from),
          till: formatDate(till),
          type: type.value,
          count :0
        };

        if(customerNo.value != '') {
          params['customer_no'] = customerNo.value;
        }

        if(license.value == true) {
          params['license'] = '1';
        }
        
        axios.get(api + '/statistics/sales', {
          params: params,
          auth: {
            username: sessionStorage.getItem('auth_user'),
            password: sessionStorage.getItem('auth_pw'),
        }})
        .then(response => { 
          isGettingData.value = false;  
          if(response && response.data) {

            var arr = [];
            arr.push(elements);

            for(let i=0; i<response.data.length; i++) {
              let temp = [];
              let el = response.data[i];

              for(let j=0; j<elements.length; j++) {
                temp.push(el[elements[j]]);
              }
              arr.push(temp);
            }
            
            let csvContent = "data:text/csv;charset=utf-8," 
              + arr.map(e => e.join(",")).join("\n");
            var encodedUri = encodeURI(csvContent);
            
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "sales.csv");
            document.body.appendChild(link);

            link.click();  
          }     
        })
        .catch(() => {
          isGettingData.value = false;             
        })
      }

      function resetSales() {
        document.getElementById('from-sales').value = '';
        document.getElementById('till-sales').value = '';
        isGettingData.value = false; 
        type.value = '';
        license.value = false;
        customerNo.value = '';
        items.value = [];
      }

      return {
        isGettingData,
        resetSales,
        exportSales,
        getSales,
        type,
        customerNo,
        license,
        items
      }
    },
    mounted() {
      
      new window.dtsel.DTS('input[name="from-sales"]', {
        dateFormat: "dd.mm.yyyy"
      });

      new window.dtsel.DTS('input[name="till-sales"]', {
        dateFormat: "dd.mm.yyyy"
      });
    }
  }
  </script>
  <style scoped>
  
  #filter-sales table {
    margin-bottom: 20px;
  }

  #filter-sales .export{
    text-align: right;
    font-size:25px;
  } 

</style>